import { TOKEN_EXPIRE } from "../config";
import { CustomResponse } from "@/api/type";
import store from "@/store";

export const TOKEN_KEY = "token";
export const UID_KEY = "uid";
export const INFO_KEY = "info";
export type AuthToken = {
  timestamp: number;
  value: string;
} | null;
let token: AuthToken = null;

export const getUserId = function () {
  let id = store.getters.uid;
  if (!id) {
    const sid = localStorage.getItem(UID_KEY);
    if (sid) {
      id = Number(sid);
      store.commit("setUid", id);
    }
  }
  return id || 0;
};

export const getUser = function () {
  let user = store.getters.user;
  if (!user) {
    const u = getStorage(INFO_KEY);
    if (u) {
      user = u;
      store.commit("setUser", user);
    }
  }
  return user;
};

export const getToken: () => AuthToken = function () {
  if (!token) {
    console.log("Read token from storage.");
    token = getStorage(TOKEN_KEY);
  }
  //判断token是否过期 过期返回null
  if (token && Date.now() - token.timestamp > TOKEN_EXPIRE) {
    token = null;
  }
  // token = {
  //   timestamp: Date.now(),
  //   value: "0077247bfb78a730e5c78663ff7ee603",
  // };
  // localStorage.setItem(UID_KEY, "5");
  return token;
};

export const setToken = function (data: CustomResponse) {
  if (data.token) {
    const temp = {
      timestamp: Date.now(),
      value: data.token,
    };
    const user = data.WxUser;
    const value = JSON.stringify(temp);
    token = temp;
    store.commit("setToken", token);
    localStorage.setItem(TOKEN_KEY, value);
    if (user) {
      store.commit("setUid", user.id);
      store.commit("setUser", user);
      localStorage.setItem(UID_KEY, user.id + "");
      localStorage.setItem(INFO_KEY, JSON.stringify(user));
    }
  }
};

export const getStorage: (key: string) => AuthToken = function (key: string) {
  let result = null;
  const storage = String(localStorage.getItem(key));
  try {
    result = JSON.parse(storage);
  } catch (e) {
    console.log(`Storage for ${key} parse failed.`);
  }
  return result;
};

export const clearStorage = function (): void {
  token = null;
  localStorage.removeItem(TOKEN_KEY);
};
