import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { hideUI } from "@/ui";
import store from "@/store";
const base = "/";

// 路由历史记录
const paths = {
  back: history.state?.back,
  forward: history.state?.forward,
};

// 缓存的路由url
const cachePaths = [{ path: "/home", component: "HomeView" }];

store.commit(
  "setInclude",
  cachePaths.map((item) => item.component)
);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "page",
    redirect: { name: "home", replace: true },
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/info",
    name: "info",
    component: () => import(/* webpackChunkName: "info" */ "../views/Info.vue"),
  },
  {
    path: "/seat",
    name: "seat",
    component: () =>
      import(/* webpackChunkName: "info" */ "../views/seat/SeatView.vue"),
  },
  {
    path: "/reception/:id",
    name: "reception",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/reception/Reception.vue"),
    children: [
      {
        path: "",
        name: "receptionHome",
        component: () =>
          import(
            /* webpackChunkName: "receptionHome" */ "../views/reception/Home.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "al",
        name: "ArticleList",
        component: () =>
          import(
            /* webpackChunkName: "al" */ "../views/reception/ArticleList.vue"
          ),
        meta: {
          title: "活动列表",
        },
      },
      {
        path: "ad/:aid",
        name: "ArticleDetail",
        component: () =>
          import(
            /* webpackChunkName: "ad" */ "../views/reception/ArticleDetail.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "fl",
        name: "FileList",
        component: () =>
          import(
            /* webpackChunkName: "al" */ "../views/reception/FileList.vue"
          ),
        meta: {
          title: "文件列表",
        },
      },
      {
        path: "ll",
        name: "LiveList",
        component: () =>
          import(
            /* webpackChunkName: "ll" */ "../views/reception/LiveList.vue"
          ),
        meta: {
          title: "直播视频列表",
        },
      },
      {
        path: "ld/:lid",
        name: "LiveDetail",
        component: () =>
          import(
            /* webpackChunkName: "ld" */ "../views/reception/LiveDetail.vue"
          ),
        meta: {
          title: "视频列表",
        },
      },
      {
        path: "schedule",
        name: "Schedule",
        component: () =>
          import(
            /* webpackChunkName: "schedule" */ "../views/reception/Schedule.vue"
          ),
        meta: {
          title: "活动日程",
        },
      },
      {
        path: "invite",
        name: "invite",
        component: () =>
          import(/* webpackChunkName: "invite" */ "../views/invite/Invite.vue"),
        meta: {
          title: "邀请函",
        },
      },
      {
        path: "sign",
        name: "sign",
        component: () =>
          import(/* webpackChunkName: "sign" */ "../views/sign/Sign.vue"),
        meta: {
          title: "签到",
        },
      },
      {
        path: "dinner",
        name: "dinner",
        component: () =>
          import(
            /* webpackChunkName: "dinner" */ "../views/reception/Dinner.vue"
          ),
        meta: {
          title: "用餐安排",
        },
      },
      {
        path: "stay",
        name: "stay",
        component: () =>
          import(/* webpackChunkName: "stay" */ "../views/reception/Stay.vue"),
        meta: {
          title: "住宿安排",
        },
      },
      {
        path: "journey",
        name: "journey",
        component: () =>
          import(
            /* webpackChunkName: "journey" */ "../views/reception/Journey.vue"
          ),
        meta: {
          title: "行程安排",
        },
      },

      {
        path: "enroll",
        name: "enroll",
        component: () =>
          import(
            /* webpackChunkName: "enroll" */ "../views/reception/Enroll.vue"
          ),
        meta: {
          title: "报名",
        },
      },
      {
        path: "guide",
        name: "guide",
        component: () =>
          import(
            /* webpackChunkName: "guide" */ "../views/reception/Guide.vue"
          ),
        meta: {
          title: "交通导览",
        },
      },
      {
        path: "voucher",
        name: "voucher",
        component: () =>
          import(
            /* webpackChunkName: "voucher" */ "../views/reception/Voucher.vue"
          ),
        meta: {
          title: "电子凭证",
        },
      },
      {
        path: "forum",
        name: "forum",
        component: () =>
          import(
            /* webpackChunkName: "forum" */ "../views/reception/ForumList.vue"
          ),
        meta: {
          title: "会议论坛",
        },
      },
      {
        path: "forum-detail",
        name: "forumDetail",
        component: () =>
          import(
            /* webpackChunkName: "forumDetail" */ "../views/reception/ForumDetail.vue"
          ),
        meta: {
          title: "论坛",
        },
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
    meta: {
      title: "正在登陆...",
    },
  },
];

const router = createRouter({
  history: createWebHistory(base),
  routes,
});

router.beforeEach((to, from, next) => {
  hideUI();
  // 返回上一个路由采用返回的过渡动画
  const back = paths.back === to.fullPath;
  if (back) {
    from.meta.transition = from.meta.transition || "slide-left";
    to.meta.transition = to.meta.transition || "slide-right";
  } else {
    // 不是初始页面
    if (from.name) {
      from.meta.transition = from.meta.transition || "slide-right";
      to.meta.transition = to.meta.transition || "slide-left";
    } else {
      to.meta.transition = "immediately";
    }
  }
  // 在缓存的路由页面后退则去掉缓存
  const index = cachePaths.findIndex((p) => p.path === from.fullPath);
  if (back && index >= 0) {
    store.commit("setExclude", [cachePaths[index].component]);
  } else {
    store.commit("setExclude", []);
  }
  const meta = to.meta;
  if (typeof meta === "object" && meta.title) {
    document.title = meta.title + "";
  }
  next();
});
router.afterEach(() => {
  paths.back = history.state.back;
  paths.forward = history.state.forward;
});
export default router;
