import { EntityListData } from "..";
import http from "../request";

export interface Enroll {
  id: number;
  activity_id: number;
  user_id: number;
  name: string;
  mobile: string;
  gender: number;
  type: number;
  num: string;
  work: string;
  position: string;
  id_type: string;
  meeting: string;
  sign_status: number;
  create_time: string;
  update_time: string;
}

export type EnrollData = Omit<Enroll, "id" | "create_time" | "update_time">;

export interface EnrollApi {
  insertEnroll: (data: EnrollData) => Promise<Enroll>;
  getQueryEnroll: (query: string) => Promise<Enroll | null>;
  checkinEnroll: (
    id: number,
    data: { sign_status: number; user_id?: number }
  ) => Promise<Enroll>;
}

export const idTypeOptions = ["参会人员", "工作人员", "媒体人员", "安保人员"];

export const enrollMeetingOptions = [
  "人才大会开幕式",
  "2023中国留学生论坛",
  "西湖对话",
  "“我们在杭州”外国人才创新成果展",
  "海外人才项目对接会",
  "“创客天下·杭向未来”2023杭州海外高层次人才创新创业大赛总决赛",
  "乡村人才振兴促进共同富裕大会",
];

const enrollApi: EnrollApi = {
  insertEnroll(data) {
    return http.post<Enroll>("/activity-enroll", data).then((res) => res.data);
  },
  getQueryEnroll(query) {
    return http
      .get<EntityListData<Enroll>>(`/activity-enroll?pageSize=1${query}`)
      .then((res) => {
        const list = res.data.data;
        if (list.length > 0) {
          return list[0];
        } else {
          return null;
        }
      });
  },
  checkinEnroll(id, data) {
    return http
      .patch<Enroll>(`/activity-enroll/${id}`, data)
      .then((res) => res.data);
  },
};

export default enrollApi;
