import http from "../request";
export interface Seat {
  id: number;
  chinese_name: string;
  english_name: string;
  region: string;
  arrange: string;
  number: number;
}

export async function searchSeat(name: string) {
  return http
    .get<Seat | null>("/common/seat-list", { params: { name } })
    .then((res) => res.data);
}
