export default {
  scrollX: Boolean,
  scrollY: Boolean,
  /* 距离边界多远时触发触底事件 */
  offset: {
    type: Number,
    default: 50,
  },
  // 是否最外层页面自有的滚动
  scrollBase: Boolean,
};
