import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "c-upload" }
const _hoisted_2 = { class: "file-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "icon-play"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "file-item"
}
const _hoisted_7 = { class: "file-box add-btn" }
const _hoisted_8 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (file, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "file-item",
          key: i
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["file-box", { video: _ctx.type == 2 }]),
            onClick: ($event: any) => (_ctx.operate(i))
          }, [
            (_ctx.type == 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: file,
                  class: "file-img"
                }, null, 8, _hoisted_5))
          ], 10, _hoisted_3)
        ]))
      }), 128)),
      (_ctx.list.length < _ctx.count)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("input", {
                type: "file",
                class: "select-file",
                accept: _ctx.fileAccept,
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
              }, null, 40, _hoisted_8)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}