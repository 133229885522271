import { PropType } from "vue";
import { LabelPosition } from "./type";

export default {
  /**
   * 表单项校验字段名
   */
  prop: String,
  /**
   * 表单项标题
   */
  label: String,
  /**
   * 表单项标题宽度
   */
  labelWidth: String,
  /**
   * 表单项标题位置
   */
  position: {
    type: String as PropType<LabelPosition>,
    default: "left",
  },
  /**
   * 头部间距
   */
  gap: Boolean,
  /**
   * 是否必填
   */
  must: Boolean,
};
