import { App, createApp, Plugin } from "vue";
import ActionSheet from "./actionSheet";
import { ActionSheetMethod } from "./type";
type ActionSheetPlugin = Plugin & ActionSheetMethod;
type ActionSheetInstance = InstanceType<typeof ActionSheet>;
let actionSheetInstance: ActionSheetInstance | null = null;
let actionSheetApp: App | null = null;

export const hideActionSheet = function () {
  if (actionSheetInstance) {
    document.body.removeChild(actionSheetInstance.$el);
    actionSheetInstance = null;
  }
  if (actionSheetApp) {
    actionSheetApp.unmount();
    actionSheetApp = null;
  }
};

const createActionSheet: ActionSheetMethod = (props) => {
  hideActionSheet();
  const options: vm.Data = { ...props };
  const box = document.createElement("div");
  actionSheetApp = createApp(ActionSheet, options);
  const instance: ActionSheetInstance = actionSheetApp.mount(
    box
  ) as ActionSheetInstance;
  document.body.appendChild(instance.$el);
  actionSheetInstance = instance;
  actionSheetInstance.showActionSheet();
};
const ActionSheetPlugin: ActionSheetPlugin =
  createActionSheet as ActionSheetPlugin;

ActionSheetPlugin.install = (app: App) => {
  app.config.globalProperties.$showActionSheet = ActionSheetPlugin;
};

export default ActionSheetPlugin;
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $showActionSheet: ActionSheetMethod;
  }
}
