
import { defineComponent } from "vue";
import CalendarProps from "./props";
import { CalendarData, DateTable, MonthData, DateObject } from "./type";
import { getDate, getDoubleText } from "@/common/tool";
import date from "@/common/date";
import CIcon from "@/ui/icon";

export default defineComponent({
  name: "dateCalendar",
  components: { CIcon },
  props: { ...CalendarProps },
  data(): CalendarData {
    return {
      months: {
        prev: [],
        current: [],
        next: [],
      },
      moveInfo: {
        start: 0,
        y: 0,
        position: 0,
        moved: false,
        animation: false,
      },
      height: 0,
      date: date.month(1),
    };
  },
  computed: {
    startTime(): number {
      let res = 0;
      if (this.startDate) {
        res = getDate(this.startDate)?.getTime() || 0;
      }
      return res;
    },
    endTime(): number {
      let res = 0;
      if (this.endDate) {
        res = getDate(this.endDate)?.getTime() || 0;
      }
      return res;
    },
  },
  mounted(): void {
    if (this.selected) {
      this.date = new Date(this.selected.slice(0, -2) + "01");
    }
    this.months.current = this.getDateArray();
    this.$nextTick(() => {
      let height = this.$el.querySelector(".month").clientHeight;
      this.height = height;
    });
  },
  methods: {
    reduceYear(): void {
      this.date = new Date(this.date.setFullYear(this.date.getFullYear() - 1));
      this.updateCalendar();
    },
    plusYear: function () {
      this.date = new Date(this.date.setFullYear(this.date.getFullYear() + 1));
      this.updateCalendar();
    },
    reduceMonth(): void {
      this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
      this.updateCalendar();
    },
    plusMonth(): void {
      this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
      this.updateCalendar();
    },
    updateCalendar(): void {
      this.months = {
        prev: [],
        current: this.getDateArray(),
        next: [],
      };
    },
    selectDate(index: number, i: number): void {
      let date = this.months.current[index][i];
      if (!date.disabled) {
        this.$emit("change", date);
      }
    },
    moveStart(e: TouchEvent): void {
      this.moveInfo.moved = false;
      this.moveInfo.animation = false;
      this.moveInfo.start = e.targetTouches[0].pageY;
      this.moveInfo.position = this.moveInfo.y;
    },
    moving: function (e: TouchEvent) {
      let distance = e.targetTouches[0].pageY - this.moveInfo.start;
      if (distance == 0 && !this.moveInfo.moved) {
        return;
      }
      e.preventDefault();
      this.moveInfo.moved = true;
      let offset = distance + this.moveInfo.position;
      let current = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate()
      );
      if (distance > 0) {
        let height = this.$el.querySelector(".prev").clientHeight;
        //向下滑动超出已有日历范围，构建上个月的日历
        if (offset > height) {
          let month = new Date(current.setMonth(this.date.getMonth() - 1));
          this.months.prev.unshift(this.getDateArray(month));
        }
      } else if (distance < 0) {
        let height = this.$el.querySelector(".next").clientHeight;
        //向上滑动超出已有日历范围，构建下个月的日历
        if (offset < -1 * height) {
          let month = new Date(current.setMonth(this.date.getMonth() + 1));
          this.months.next.push(this.getDateArray(month));
        }
      }
      this.moveInfo.y = offset;
    },
    moveEnd: function (e: TouchEvent) {
      if (!this.moveInfo.moved) {
        return;
      }
      e.preventDefault();
      let y = this.moveInfo.y;
      //滚动到初始位置不做任何响应
      if (y == 0) {
        return;
      }
      let current = this.$el.querySelector(".current");
      let unit = current.querySelector(".td")?.clientHeight || 0;
      //向上滚动还是向下滚动
      let key: keyof MonthData = y > 0 ? "prev" : "next";
      let coe = y > 0 ? 1 : -1;
      let list = this.months[key];
      let index: number,
        h = 0;
      if (y > 0) {
        //根据滚动位置计算当前滚动到哪个月份
        for (let i = 0, l = list.length; i < l; i++) {
          let height = this.$el.querySelector(`.${key}-${i}`).clientHeight;
          let offset = h + height - y;
          if (offset > 0) {
            let distance = y - h;
            if (distance >= unit) {
              index = i;
              this.moveInfo.y = (h + height) * coe;
              this.moveInfo.animation = true;
              this.height = height;
            } else if (distance > 0) {
              index = i - 1;
              if (i - 1 > 0) {
                this.height = this.$el.querySelector(
                  `.${key}-${index}`
                ).clientHeight;
              }
              this.moveInfo.y = h * coe;
              this.moveInfo.animation = true;
            }
            setTimeout(() => {
              this.moveInfo.animation = false;
              let temp = (index + 1) * coe;
              this.date.setMonth(this.date.getMonth() - temp);
              temp != 0 && this.$emit("move", this.date);
              let last = index == -1 ? this.months.current : list[index];
              this.$nextTick(() => {
                this.moveInfo.y = 0;
                this.months = {
                  prev: [],
                  current: last,
                  next: [],
                };
              });
            }, 300);
            break;
          } else {
            h += height;
            continue;
          }
        }
      } else {
        y = Math.abs(y);
        for (let j = 0, l = list.length; j < l; j++) {
          let height = this.$el.querySelector(`.${key}-${j}`).clientHeight;
          let add =
            j == 0
              ? current.clientHeight
              : this.$el.querySelector(`.${key}-${j - 1}`).clientHeight;
          if (y > h && y < h + add) {
            let distance = y - h;
            if (distance >= unit) {
              index = j;
              this.moveInfo.y = (h + add) * coe;
              this.moveInfo.animation = true;
              this.height = height;
            } else if (distance > 0) {
              index = j - 1;
              if (j - 1 > 0) {
                this.height = this.$el.querySelector(
                  `.${key}-${index}`
                ).clientHeight;
              }
              this.moveInfo.y = h * coe;
              this.moveInfo.animation = true;
            }
            setTimeout(() => {
              this.moveInfo.animation = false;
              let temp = (index + 1) * coe;
              this.date.setMonth(this.date.getMonth() - temp);
              temp != 0 && this.$emit("move", this.date);
              let last = index == -1 ? this.months.current : list[index];
              this.$nextTick(() => {
                this.moveInfo.y = 0;
                this.months = {
                  prev: [],
                  current: last,
                  next: [],
                };
              });
            }, 300);
            break;
          } else {
            h += height;
            continue;
          }
        }
      }
    },
    getDateArray(date?: Date): DateTable {
      let time = date ? date : this.date;
      let year = time.getFullYear();
      let month = time.getMonth();
      let firstDay = new Date(year, month, 1); //当月第一天
      let weekDay = firstDay.getDay(); //当月第一天是星期几
      let dayArray = this.getMonthDays(year);
      let weekNum = Math.ceil((dayArray[month] + weekDay) / 7); //当月显示周数
      let trList = [];
      for (let i = 0; i < weekNum; i++) {
        let tdList = [];
        for (let j = 0; j < 7; j++) {
          let index = i * 7 + j; //表格单元的自然序号
          let date = index - weekDay + 1; //计算日期
          let dateObj: DateObject, ty, tm, td;
          let inMonth = false;
          if (date <= 0) {
            tm = month - 1 < 0 ? 11 : month - 1;
            ty = month - 1 < 0 ? year - 1 : year;
            td = dayArray[tm] + date;
          } else if (date > dayArray[month]) {
            tm = month + 1 > 11 ? 0 : month + 1;
            ty = month + 1 > 11 ? year + 1 : year;
            td = date - dayArray[month];
          } else {
            ty = year;
            tm = month;
            td = date;
            inMonth = true;
          }
          let dateStr = this.getDateString(ty, tm, td);
          dateObj = {
            weekday: j,
            day: td,
            isToday: this.isToday(ty, tm, td),
            dateStr: dateStr,
            timestamp: new Date(ty, tm, td).getTime(),
            inMonth,
            classes: [],
            disabled: false,
          };
          dateObj.disabled = this.isDisableDay(dateObj);
          let classes: Array<string> = [];
          if (j == 0 || j == 6) {
            classes.push("weekend");
          }
          if (!inMonth) {
            classes.push("out-month");
          }
          if (dateObj.disabled) {
            classes.push("date-disable");
          }
          dateObj.classes = classes;
          tdList.push(dateObj);
        }
        trList.push(tdList);
      }
      return trList;
    },
    isDisableDay(day: DateObject) {
      let { startTime, endTime } = this;
      let disabled = this.disabled;
      return (
        disabled ||
        (!!startTime && day.timestamp < startTime) ||
        (!!endTime && day.timestamp > endTime)
      );
    },
    isToday(ty: number, tm: number, td: number): boolean {
      let d = new Date();
      return d.getFullYear() == ty && d.getMonth() == tm && d.getDate() == td;
    },
    getDateString(year: number, month: number, date: number): string {
      return `${year}-${getDoubleText(month + 1 + "")}-${getDoubleText(
        date + ""
      )}`;
    },
    isLeap: function (year: number) {
      return year % 100 == 0
        ? year % 400 == 0
          ? 1
          : 0
        : year % 4 == 0
        ? 1
        : 0;
    },
    getMonthDays: function (year: number) {
      return [
        31,
        28 + this.isLeap(year),
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
      ];
    },
  },
});
