import { RouteState, RoutePattern } from "@/store/type";

export default {
  state: {
    /* 应用缓存的路由 */
    include: [],
    /* 不缓存的路由 */
    exclude: [],
  },
  getters: {
    include: (state: RouteState) => state.include,
    exclude: (state: RouteState) => state.exclude,
  },
  mutations: {
    setInclude(state: RouteState, value: RoutePattern) {
      state.include = value;
    },
    setExclude(state: RouteState, value: RoutePattern) {
      state.exclude = value;
    },
  },
  actions: {},
};
