import http from "../request";
import { EntityListData } from "../type";

export interface Article {
  id: number;
  title: string;
  author: string;
  type: number;
  content: string;
  num: number;
  is_redirect: number;
  create_time: string;
  update_time: string;
}

export interface ArticleApi {
  getArticleById: (id: number) => Promise<Article>;
  getArticleByType: (type: number) => Promise<Article[]>;
}

const articleApi: ArticleApi = {
  getArticleById(id) {
    return http.get<Article>(`/zsfz-article/${id}`).then((res) => res.data);
  },
  getArticleByType(type: number) {
    return http
      .get<EntityListData<Article>>(
        `/zsfz-article?pageSize=1000&sort=-id&filter[type]=${type}`
      )
      .then((res) => res.data.data);
  },
};

export default articleApi;
