import http from "../request";
import { Guest } from "./guest";
import { Hotel } from "./hotel";
import { Room } from "./room";
import { EntityListData } from "@/api";

export interface HotelAllocation {
  id: number;
  activity_id: number;
  dining_id: number;
  guest_id: number;
  hotel: Hotel | null;
  hotelRoom: Room | null;
  create_time: string;
  update_time: string;
}

export interface HotelAllocationApi {
  getUserHotelAllocation: (guest: Guest) => Promise<HotelAllocation | null>;
}

const hotelAllocationApi: HotelAllocationApi = {
  getUserHotelAllocation(guest) {
    const url = `/activity-hotel-allocation?pageSize=1&expand=guest&filter[guest_id]=${guest.id}&filter[activity_id]=${guest.activity_id}`;
    return http.get<EntityListData<HotelAllocation>>(url).then((res) => {
      const list = res.data.data;
      if (list.length > 0) {
        return list[0];
      } else {
        return null;
      }
    });
  },
};

export default hotelAllocationApi;
