
import { defineComponent } from "vue";
import mixin from "../form/mixin";
import uploadProps from "./props";
import { CUploadData } from "./type";
import { getFileSizeText, modalTip } from "@/common/tool";
import { previewImage } from "@/common/wx";

export default defineComponent({
  name: "CUpload",
  mixins: [mixin],
  props: { ...uploadProps },
  data(): CUploadData {
    return {
      list: [],
    };
  },
  computed: {
    limitText(): string {
      return getFileSizeText(this.limit);
    },
    fileAccept(): string {
      if (this.type == 2) {
        return "video/*";
      } else {
        return "image/*";
      }
    },
  },
  watch: {
    modalValue() {
      this.setFileList();
    },
  },
  created(): void {
    this.setFileList();
  },
  methods: {
    setFileList() {
      const val = this.modelValue;
      if (val) {
        this.list = [...val];
      } else {
        this.list = [];
      }
    },
    selectFile(e: Event) {
      const input = e.currentTarget as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const limit = this.type == 2 ? this.limit : this.maxVideoSize;
        let flag = true;
        for (let i = 0, l = input.files.length; i < l; i++) {
          const file = input.files[i];
          if (file.size > limit) {
            modalTip(
              `您选择的第${i + 1}${
                this.type === 2 ? "个视频" : "张图片"
              }过大（最大不能超过${this.limitText}），请重新选择！`
            );
            flag = false;
            break;
          }
        }
        if (flag) {
          this.$showLoading("正在上传");
          const pro: Array<Promise<string>> = [];
          for (let i = 0, l = input.files.length; i < l; i++) {
            const file = input.files[i];
            pro.push(this.$api.uploadFile({ file }));
          }
          Promise.all(pro)
            .then((list) => {
              this.list.push(...list);
              this.dispatchChange();
            })
            .finally(() => {
              this.$hideLoading();
            });
        }
      }
    },
    dispatchChange() {
      this.$emit("update:modelValue", [...this.list]);
    },
    operate(i: number) {
      this.$showActionSheet({
        itemList: ["预览", "删除"],
        fn: (tab: number) => {
          if (tab == 0) {
            previewImage(this.list[i], this.list);
          } else {
            this.list.splice(i, 1);
            this.dispatchChange();
          }
        },
      });
    },
  },
});
