import { DESENSITIZE_INFO } from "@/config";
import store from "@/store";
import { Modal } from "@/ui";

type UserInfoItemType = "name" | "mobile" | "idCard" | "num";
/**
 * 缺省显示用户敏感信息
 * @param str 敏感信息
 * @param type 信息类型
 */

export const getPartUserInfoItem = function (
  str: string,
  type: UserInfoItemType
): string {
  let res = str;
  if (DESENSITIZE_INFO) {
    switch (type) {
      case "name":
        res = "*" + str.substring(1);
        break;
      case "mobile":
        res = str.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2");
        break;
      case "idCard":
        res =
          str.substring(0, 1) +
          new Array(16).fill("*").join("") +
          str.substring(str.length - 1);
        break;
      // 罪犯编号显示前1/3和后 1/3 ，其他用* 号代替。
      case "num":
        if (str.length > 3) {
          const len = Math.floor(str.length / 3);
          res =
            str.substring(0, len) +
            new Array(str.length - len * 2).fill("*").join("") +
            str.substring(str.length - len);
        }
        break;
    }
  }
  return res;
};

export const checkUserEnroll = () => {
  return store.dispatch("setUserEnroll").then((enroll) => {
    if (enroll) {
      return Promise.resolve(enroll);
    } else {
      Modal({
        title: "温馨提示",
        content: "系统暂无您的信息，请前往“校友征集令”填写信息",
        confirmBtn: "去填写",
        onConfirm: () => {
          location.href = "/summon.html";
        },
      });
      return Promise.reject();
    }
  });
};
