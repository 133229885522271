import { fadeInKeyframes, fadeOutKeyframes } from "./keyframes";
const ANIMATION_OPTION = {
  duration: 200,
};

export type AnimationFinishListener = () => void;

export const fadeIn = function (
  dom: HTMLElement,
  onFinish?: AnimationFinishListener
): Animation {
  const animation = dom?.animate(fadeInKeyframes, ANIMATION_OPTION);
  animation.addEventListener("finish", () => {
    typeof onFinish === "function" && onFinish();
  });
  return animation;
};

export const fadeOut = function (
  dom: HTMLElement,
  onFinish?: AnimationFinishListener
): Animation {
  const animation = dom?.animate(fadeOutKeyframes, ANIMATION_OPTION);
  animation.addEventListener("finish", () => {
    typeof onFinish === "function" && onFinish();
  });
  return animation;
};
