import { PropType } from "vue";
import { TabBarProps } from "@/components/tab-bar/type";

export default {
  tabs: {
    type: Array as PropType<TabBarProps["tabs"]>,
    default() {
      return [];
    },
  },
  color: String,
  selectColor: String,
  tab: {
    type: Number,
    default: 0,
  },
};
