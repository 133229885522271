import { EntityState } from "@/store/type";

export default {
  state: {
    audio: null,
  },
  getters: {
    audio: (state: EntityState) => state.audio,
  },
  mutations: {
    setAudio(state: EntityState, value: HTMLAudioElement | null) {
      state.audio = value;
    },
  },
};
