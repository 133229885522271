import { PropType } from "vue";
import { CModalProps, ModalIconType } from "./type";

export default {
  /**
   * 弹框头部内容
   */
  title: {
    type: [String, Object, Function] as PropType<CModalProps["title"]>,
    required: true,
  },
  /**
   * 弹框内容
   */
  content: {
    type: [String, Object, Function] as PropType<CModalProps["content"]>,
  },
  /**
   * 弹框位置
   */
  position: {
    type: String as PropType<CModalProps["position"]>,
    default: "center",
  },
  /**
   * 取消按钮 支持文本和自定义 false不显示取消按钮
   */
  cancelBtn: {
    type: [String, Boolean, Object, Function] as PropType<
      CModalProps["cancelBtn"]
    >,
    default: "取消",
  },
  /**
   * 确认按钮 支持文本和自定义
   */
  confirmBtn: {
    type: [String, Object, Function] as PropType<CModalProps["confirmBtn"]>,
    default: "确定",
  },
  /**
   * 弹框图标 不传不显示
   */
  icon: {
    type: String as PropType<ModalIconType>,
  },
  /**
   * 弹框类型
   */
  modalType: {
    type: String as PropType<CModalProps["modalType"]>,
    default: "normal",
  },
  /**
   * 点击取消回调
   */
  onCancel: Function as PropType<CModalProps["onCancel"]>,
  /**
   * 点击确认回调
   */
  onConfirm: Function as PropType<CModalProps["onConfirm"]>,
  /**
   * 点击确认后是否不关闭弹框
   */
  hold: Boolean,
};
