
import { defineComponent } from "vue";
import FormAreaProps from "./props";
import {
  FormAreaData,
  FormItemProp,
  FormItem,
  RelyConfig,
  FormValue,
} from "@/components/form-area/type";
import { FormRules } from "@/ui/form/type";
import { CFormInstance } from "@/ui/form";

export default defineComponent({
  name: "formArea",
  props: { ...FormAreaProps },
  data(): FormAreaData {
    return {
      form: {},
      rely: {},
      items: [],
    };
  },
  watch: {
    config() {
      this.setRely();
    },
    value() {
      this.setForm();
    },
  },
  computed: {
    rules(): FormRules {
      const res: FormRules = {};
      const config = this.config;
      config.forEach((item) => {
        const key = item.key;
        if (item.rules && item.rules.length > 0) {
          res[key] = item.rules;
        }
      });
      return res;
    },
  },
  created(): void {
    this.setForm();
    this.setRely();
  },
  methods: {
    handleChange(value: unknown, item: FormItem) {
      const rely = this.rely;
      if (rely[item.key]) {
        this.setItems();
      }
    },
    getItemProps(item: FormItem): FormItemProp {
      const prop: FormItemProp = item.props || {};
      if (item.need) {
        prop.nv = this.form[item.need.key];
      }
      return prop;
    },
    syncSubmit(): { error: string; data: FormValue } {
      let error = "",
        data: FormValue = {};
      const form: CFormInstance = this.$refs.form as CFormInstance;
      error = form.validate();
      if (!error) {
        this.items.forEach((item: FormItem) => {
          data[item.key] = this.form[item.key];
        });
        this.$emit("submit", data);
      }
      return { error, data };
    },
    submit(): Promise<FormValue> {
      return new Promise((resolve, reject) => {
        const form: CFormInstance = this.$refs.form as CFormInstance;
        const error = form.validate();
        if (error) {
          this.$toast(error);
          reject(error);
        } else {
          const data: FormValue = {};
          this.items.forEach((item: FormItem) => {
            data[item.key] = this.form[item.key];
          });
          this.$emit("submit", data);
          resolve(data);
        }
      });
    },
    setForm() {
      let val = this.value;
      const config = this.config;
      if (val) {
        for (let key in val) {
          if (config.findIndex((c) => c.key == key) >= 0) {
            this.form[key] = val[key];
          }
        }
      } else {
        this.form = {};
      }
    },
    setItems() {
      const config = this.config;
      const items: Array<FormItem> = [];
      if (config) {
        config.forEach((item) => {
          if (
            !item.rely ||
            this.getRelyFlag(item.rely, this.form[item.rely.key])
          ) {
            items.push(item);
          }
        });
      }
      this.items = items;
    },
    setRely() {
      const rely = this.rely,
        config = this.config;
      const items: Array<FormItem> = [];
      if (config) {
        config.forEach((item) => {
          const key = item.key;
          if (!item.rely || this.getRelyFlag(item.rely, this.form[key])) {
            items.push(item);
          }
          if (item.rely) {
            let k = item.rely.key;
            if (rely[k]) {
              rely[k].push(key);
            } else {
              rely[k] = [key];
            }
          }
        });
      } else {
        this.rely = {};
      }
      this.items = items;
    },
    getRelyFlag(rely: RelyConfig, value: unknown): boolean {
      let flag = false;
      let val = rely.value;
      if (typeof val === "function") {
        flag = val(value);
      } else if (val instanceof Array) {
        flag = val.indexOf(value) >= 0;
      } else {
        flag = value === val;
      }
      return flag;
    },
  },
});
