
import { defineComponent } from "vue";
import TabBarProps from "./props";
import lang from "@/components/lang-switch/i18n";

export default defineComponent({
  name: "tabBar",
  props: { ...TabBarProps },
  setup(props, { emit }) {
    return {
      lang,
      tabClick: (i: number) => {
        emit("change", i);
      },
    };
  },
});
