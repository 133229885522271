import { App, Plugin } from "vue";
import axios, { AxiosInstance } from "axios";
import { HOST } from "../config";
import auth from "./auth";
import { getToken } from "@/common/storage";
import { Toast } from "@/ui";

type HttpPlugin = Plugin & AxiosInstance;
const HttpPlugin: HttpPlugin = axios.create({
  baseURL: HOST,
  responseType: "json",
}) as HttpPlugin;

let controller: AbortController;

HttpPlugin.interceptors.request.use((config) => {
  const token = getToken();
  const uri = config.url + "";
  if (
    !/^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])?$/.test(
      uri
    )
  ) {
    config.url = HOST + uri;
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token.value}`;
      // 文件上传请求不指定content-type(指定了 在app中formData请求不能自动设置content-type)
      if (!config.upload && !config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json";
      }
    }
    controller = new AbortController();
    config.signal = controller.signal;
  }
  return config;
});

HttpPlugin.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log("err:", err.response);
    const response = err.response;
    const status = response ? response.status : undefined;
    if (status == 401) {
      auth();
    } else if (err.response && err.response.data && err.response.data.message) {
      Toast(err.response.data.message);
    }
    return Promise.reject(err);
  }
);

HttpPlugin.install = (app: App) => {
  app.config.globalProperties.$http = HttpPlugin;
};

export default HttpPlugin;
export const abortRequest = function () {
  controller?.abort();
};
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $http: HttpPlugin;
  }
}
declare module "axios" {
  interface AxiosRequestConfig {
    /**
     * 不显示错误信息
     */
    noError?: boolean;
    /**
     * 是否是文件上传请求
     * */
    upload?: boolean;
  }
}
