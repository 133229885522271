import { defineComponent } from "vue";
import scrollViewProps from "./props";

export default defineComponent({
  name: "ScrollView",
  props: { ...scrollViewProps },
  data() {
    return {};
  },
  computed: {
    scrollClass() {
      return this.scrollBase ? "scroll-view" : "scroll-area";
    },
  },
  mounted(): void {
    this.initScroll();
  },
  beforeMount(): void {
    this.removeListener();
  },
  methods: {
    initScroll() {
      if (this.scrollBase) {
        window.addEventListener("scroll", this.pageScrollListener);
      } else {
        this.$el.addEventListener("scroll", this.domScrollListener);
      }
    },
    removeListener() {
      if (this.scrollBase) {
        window.removeEventListener("scroll", this.pageScrollListener);
      } else {
        this.$el.removeEventListener("scroll", this.domScrollListener);
      }
    },
    pageScrollListener() {
      const dom = document.documentElement || document.body;
      this.handleElementScroll(dom);
    },
    domScrollListener(e: Event) {
      const dom = e.currentTarget as HTMLElement;
      this.handleElementScroll(dom);
    },
    handleElementScroll(dom: HTMLElement) {
      if (dom.scrollTop + dom.offsetHeight >= dom.scrollHeight - this.offset) {
        this.$emit("scrollToLower");
      }
    },
  },
  render() {
    return <div class={this.scrollClass}>{this.$slots.default?.()}</div>;
  },
});
