import http from "@/api/request";
import { EntityListData, Guest, Journey, Vehicle } from "@/api";

export interface VehicleAllocation {
  id: number;
  activity_id: number;
  vehicle_id: number;
  journey_id: number;
  guest_id: number;
  delivery_start_time: string;
  delivery_end_time: string;
  vehicle: Vehicle | null;
  journey: Journey | null;
  create_time: string;
  update_time: string;
}

export interface VehicleAllocationApi {
  getUserVehicleAllocation: (guest: Guest) => Promise<VehicleAllocation | null>;
}

const vehicleAllocationApi: VehicleAllocationApi = {
  getUserVehicleAllocation(guest) {
    const url = `/activity-vehicle-allocation?pageSize=1&expand=guest,journey.receptionist&filter[guest_id]=${guest.id}&filter[activity_id]=${guest.activity_id}`;
    return http.get<EntityListData<VehicleAllocation>>(url).then((res) => {
      const list = res.data.data;
      if (list.length > 0) {
        return list[0];
      } else {
        return null;
      }
    });
  },
};

export default vehicleAllocationApi;
