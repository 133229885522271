
import { defineComponent } from "vue";

export default defineComponent({
  name: "userAvatar",
  props: {
    url: String,
  },
  data() {
    return {
      error: false,
    };
  },
  methods: {
    loadError() {
      this.error = true;
    },
  },
});
