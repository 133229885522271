import { getDate, getTimePeriod } from "@/common/tool";

export interface Dinner {
  id: number;
  activity_id: number;
  hotel_name: string;
  hotel_address: string;
  dinner_start_time: string;
  dinner_end_time: string;
  dining_place: string;
  create_time: string;
  update_time: string;
}

export const getDinnerTime = function (dinner: Dinner) {
  return dinner.dinner_end_time
    ? getTimePeriod(dinner.dinner_start_time, dinner.dinner_end_time)
    : getDate(dinner.dinner_start_time)?.pattern("yyyy-MM-dd HH:mm") || "";
};
