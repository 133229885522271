import { PropType } from "vue";
import { ActionSheetProps } from "./type";

export default {
  /* 警示文案 */
  alertText: String,
  /* 按钮的文字数组，数组长度最大为 6 */
  itemList: {
    type: Array as PropType<ActionSheetProps["itemList"]>,
  },
  /* 按钮的文字颜色 */
  itemColor: String,
  /* 点击按钮回调函数 */
  fn: {
    type: Function as PropType<ActionSheetProps["fn"]>,
  },
};
