import http from "../request";
import { EntityListData } from "@/api";
import { getKeyTitle } from "@/common/tool";

export interface Guest {
  id: number;
  activity_id: number;
  guest_img_id: number;
  receptionist_id: string;
  wx_user_id: number | null;
  type: number;
  name: string;
  tel: string;
  sex: number;
  id_type: number;
  id_num: string;
  sign_status: number;
  audit_status: number;
  fill_status: number;
  enroll_status: number;
  work: string;
  profession: string;
  arrival_method: number;
  arrival_number: string | null;
  arrival_driver: string | null;
  arrival_time: string;
  arrival_address: string;
  arrival_transfer: number;
  leave_method: number;
  leave_number: string | null;
  leave_driver: string | null;
  leave_time: string;
  leave_address: string;
  leave_transfer: number;
  eat_type: number;
  eat_info?: string | null;
  live_type: number;
  live_info?: string | null;
  accompany_info?: GuestData[];
  create_time: string;
  update_time: string;
}

export type GuestData = Pick<
  Guest,
  | "activity_id"
  | "wx_user_id"
  | "type"
  | "name"
  | "tel"
  | "sex"
  | "id_type"
  | "id_num"
  | "arrival_method"
  | "arrival_number"
  | "arrival_driver"
  | "arrival_time"
  | "arrival_address"
  | "leave_method"
  | "leave_number"
  | "leave_driver"
  | "leave_time"
  | "leave_address"
  | "fill_status"
  | "enroll_status"
  | "accompany_info"
>;

export interface GuestApi {
  addGuest: (data: GuestData) => Promise<Guest>;
  getQueryGuest: (query: string) => Promise<Guest | null>;
  checkinGuest: (
    id: number,
    data: { sign_status: number; wx_user_id?: number }
  ) => Promise<Guest>;
}

export const guestTypeList = [
  { key: 1, title: "嘉宾" },
  { key: 2, title: "校友" },
  { key: 3, title: "不确定人员" },
];

export const guestWayList = [
  { key: 1, title: "飞机" },
  { key: 2, title: "高铁" },
  { key: 3, title: "自驾" },
  { key: 4, title: "其他" },
];

export const getGuestTypeText = function (type: number) {
  return getKeyTitle(guestTypeList, type);
};

export const getWayTypeText = function (type: number) {
  return getKeyTitle(guestWayList, type);
};

const guestApi: GuestApi = {
  addGuest(data) {
    return http.post<Guest>("/activity-guest", data).then((res) => res.data);
  },
  getQueryGuest(query) {
    return http
      .get<EntityListData<Guest>>(
        `/activity-guest?expand=enroll&pageSize=1${query}`
      )
      .then((res) => {
        const list = res.data.data;
        if (list.length > 0) {
          return list[0];
        } else {
          return null;
        }
      });
  },
  checkinGuest(id, data) {
    return http
      .patch<Guest>(`/activity-guest/${id}`, data)
      .then((res) => res.data);
  },
};

export default guestApi;
