import http from "../request";
import { Guest } from "./guest";
import { Dinner } from "./dinner";
import { EntityListData } from "@/api";

export interface DinnerAllocation {
  id: number;
  activity_id: number;
  dining_id: number;
  guest_id: number;
  ding?: Dinner | null;
  guest?: Guest | null;
  create_time: string;
  update_time: string;
}

export interface DinnerAllocationApi {
  getUserDinnerAllocation: (guest: Guest) => Promise<DinnerAllocation | null>;
}

const dinnerAllocationApi: DinnerAllocationApi = {
  getUserDinnerAllocation(guest) {
    const url = `/activity-dining-allocation?pageSize=1&expand=guest,ding&filter[guest_id]=${guest.id}&filter[activity_id]=${guest.activity_id}`;
    return http.get<EntityListData<DinnerAllocation>>(url).then((res) => {
      const list = res.data.data;
      if (list.length > 0) {
        return list[0];
      } else {
        return null;
      }
    });
  },
};

export default dinnerAllocationApi;
