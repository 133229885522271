import { PropType } from "vue";
import { CSelectProps } from "@/ui/select/type";
export const SelectProps = {
  mode: {
    type: String as PropType<CSelectProps["mode"]>,
    default: "selector",
  },
  placeholder: {
    type: String,
    default: "请选择",
  },
  rangeKey: {
    type: String,
    default: "text",
  },
  valueKey: String,
  options: {
    type: Array as PropType<CSelectProps["options"]>,
    default() {
      return [];
    },
  },
  start: String,
  end: String,
  disabled: Boolean,
  modelValue: {
    type: [String, Number, Object, Array] as PropType<
      CSelectProps["modelValue"]
    >,
  },
};
