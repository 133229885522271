import { PropType } from "vue";
import { CRadioProps } from "@/ui/radio/type";

export const RadioProps = {
  options: {
    type: Array as PropType<CRadioProps["options"]>,
    default: "selector",
  },
  radioStyle: [String, Object, Array] as PropType<CRadioProps["radioStyle"]>,
  layout: {
    type: String as PropType<CRadioProps["layout"]>,
    default: "horizontal",
  },
  type: {
    type: String as PropType<CRadioProps["type"]>,
    default: "button",
  },
  modelValue: [Number, String],
  multiple: Boolean,
};
