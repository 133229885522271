import { PropType } from "vue";
import { CButtonProps } from "./type";

export default {
  type: {
    type: String as PropType<CButtonProps["type"]>,
    default: "primary",
  },
  size: {
    type: String as PropType<CButtonProps["size"]>,
    default: "large",
  },
  disabled: Boolean,
};
