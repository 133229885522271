import { App, createApp, Plugin } from "vue";
import { fadeIn, fadeOut } from "../animation";
import Toast from "./toast.vue";
import {
  ToastMethod,
  ToastOption,
  ToastThemeMethod,
  LoadingMethod,
  HideMethod,
} from "./type";

let toastElement: HTMLElement | null = null;
let timer: number | null = null;

const removeToastElement = function (): void {
  document.body.removeChild(toastElement as HTMLElement);
  toastElement = null;
};

const hideToast: HideMethod = (): void => {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  if (toastElement) {
    fadeOut(toastElement as HTMLElement, removeToastElement);
  }
};
const toastFunction = (props: ToastOption): void => {
  // 如果有旧的移除旧的
  toastElement && removeToastElement();
  const box = document.createElement("div");
  const instance = createApp(Toast, {
    icon: props.icon,
    title: props.title,
    mask: props.mask || false,
  }).mount(box);
  toastElement = instance.$el;
  document.body.appendChild(toastElement as HTMLElement);
  fadeIn(toastElement as HTMLElement);
};

const showToast: ToastMethod = (title): void => {
  let duration = 1500;
  title = title || { title: "", icon: "none" };
  if (typeof title === "string") {
    toastFunction({
      title,
      icon: "none",
    });
  } else if (typeof title === "object") {
    toastFunction(title);
    if (title.duration) {
      duration = title.duration;
    }
  }
  timer = setTimeout(() => {
    timer = null;
    hideToast();
  }, duration);
};

// const showThemeToast: ToastThemeMethod = (theme, title): void => {
//   showToast({ icon: theme, title });
// };

const showLoading: LoadingMethod = (title: string): void => {
  toastFunction({
    title,
    icon: "loading",
    mask: true,
  });
};

interface ExtraApi {
  success: ToastThemeMethod;
  error: ToastThemeMethod;
  showLoading: LoadingMethod;
  hideLoading: HideMethod;
}
type ToastPlugin = Plugin & ToastMethod & ExtraApi;
const extraApi: ExtraApi = {
  success: (title) => showToast({ icon: "success", title: title }),
  error: (title) => showToast({ icon: "error", title: title }),
  showLoading,
  hideLoading: hideToast,
};

const ToastPlugin: ToastPlugin = showToast as ToastPlugin;
ToastPlugin.success = extraApi["success"];
ToastPlugin.error = extraApi["error"];
ToastPlugin.showLoading = extraApi["showLoading"];
ToastPlugin.hideLoading = extraApi["hideLoading"];
ToastPlugin.install = (app: App) => {
  app.config.globalProperties.$toast = ToastPlugin;
  app.config.globalProperties.$showLoading = showLoading;
  app.config.globalProperties.$hideLoading = hideToast;
};
export default ToastPlugin;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $toast: ToastMethod & ExtraApi;
    $showLoading: LoadingMethod;
    $hideLoading: HideMethod;
  }
}
