import { PropType } from "vue";
import { CFormProps } from "./type";

export default {
  model: {
    type: Object as PropType<CFormProps["model"]>,
  },
  rules: {
    type: Object as PropType<CFormProps["rules"]>,
  },
};
