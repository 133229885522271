import http from "../request";
import { UID_KEY, INFO_KEY } from "@/common/storage";
import { getKeyTitle } from "@/common/tool";
import store from "@/store";

export interface User {
  id: number;
  mobile: string;
  avatar: string;
  uname: string;
  real_name: string;
  sex: number;
}

export interface UserApi {
  getUser: () => Promise<User>;
  updateUser: (
    id: number,
    data: Pick<User, "real_name" | "mobile" | "sex">
  ) => Promise<User>;
}

export const genderList = [
  { key: 1, title: "男" },
  { key: 2, title: "女" },
];

export const getGenderText = function (sex: number) {
  return getKeyTitle(genderList, sex);
};

const userApi: UserApi = {
  getUser() {
    const uid = localStorage.getItem(UID_KEY);
    return http.get<User>(`/wx-user/${uid}`).then((res) => res.data);
  },
  updateUser(id, data) {
    return http.patch<User>(`/wx-user/${id}`, data).then((res) => {
      const user = res.data;
      store.commit("setUser", user);
      localStorage.setItem(INFO_KEY, JSON.stringify(user));
      return user;
    });
  },
};

export default userApi;
