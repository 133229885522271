import { Article } from "@/components/control/article-item/type";
import http from "../request";
import { Live } from "@/components/control/live-item/type";

// 活动状态
export enum ActivityStatus {
  NOT_START = 1, // 未开始
  PROGRESS, // 进行中
  ENDED, // 已结束
  CREATING, // 创建中
}

// 活动
export interface Activity {
  id: number;
  temple_id?: number;
  name: string;
  create_user_id: number; // 创建人
  charge_user_name: string; // 统筹人
  charge_user_tel: string; // 手机号
  config: string; // 模板配置
  address: string; // 活动地址
  file: string; // 文件地址
  people_count: number; // 活动人数
  start_time: string; // 开始时间
  end_time: string; // 结束时间
  info: string; // 介绍
  cover?: string;
  status: ActivityStatus; // 状态
  activity_snap: string; // 活动快照
  create_time?: string;
  update_time?: string;
}

export interface ArrangeInfoItem {
  label: string;
  value: string;
  gap?: boolean;
}

export interface ArrangeInfoPiece {
  title: string;
  infoList: ArrangeInfoItem[];
}

export interface ReceptionApi {
  getActivity: (id: string | number) => Promise<Activity>;
  getArticle: (id: string | number) => Promise<Article>;
  getVideo: (id: string | number) => Promise<Live>;
}

const receptionApi: ReceptionApi = {
  getActivity(id: string | number) {
    return http.get<Activity>(`/activity/${id}`).then((res) => res.data);
  },
  getArticle(id: string | number) {
    return http.get<Article>(`/activity-article/${id}`).then((res) => res.data);
  },
  getVideo(id: string | number) {
    return http.get<Live>(`/activity-video/${id}`).then((res) => res.data);
  },
};

export default receptionApi;
