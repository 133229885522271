import { Lang } from "@/store/modules/reception";

export interface LangText {
  [key: string]: string;
}
export type LangTextMap = {
  [key in Lang]: LangText;
};

const testMap: LangTextMap = {
  zh: {
    colon: "：",
    prompts: "提示",
    confirm: "确定",
    auth: "授权",
    cancel: "取消",
    authTip: "您尚未登录，请先登录授权！",
    home: "首页",
    user: "微信用户",
    unAuth: "未登录，点击授权登录",
    center: "个人中心",
    info: "个人信息",
    journey: "行程安排",
    meal: "用餐安排",
    stay: "住宿安排",
    searching: "查询中...",
    mealInfo: "用餐信息",
    mealTime: "用餐时间",
    hotelName: "酒店名称",
    hotelAddress: "酒店地址",
    mealPlace: "用餐地点",
    mealEmpty: "暂无用餐安排",
    stayInfo: "住宿信息",
    roomType: "房型",
    stayEmpty: "暂无住宿信息",
    journeyInfo: "行程安排",
    arrivalInfo: "到达信息",
    arrivalType: "到达方式",
    arrivalNumber: "航班车次",
    carNumber: "车牌号",
    arrivalTime: "到达时间",
    pickAddress: "接站地址",
    departuresInfo: "离开信息",
    departuresType: "离开方式",
    departuresTime: "离开时间",
    vehicleInfo: "车辆安排",
    dispatchTime: "派车时间",
    driverName: "司机姓名",
    contactNumber: "联系电话",
    receptionist: "接待人员",
    name: "姓名",
    mobile: "手机号",
    sex: "性别",
    unset: "未设置",
    save: "保存",
    edit: "编辑",
    submit: "提交",
    submitTip: "确定提交吗？",
    submitSuccess: "提交成功",
    idCardError: "身份证号码格式不正确",
  },
  en: {
    colon: ":",
    prompts: "Prompts",
    confirm: "Confirm",
    auth: "Authorize",
    cancel: "Cancel",
    authTip: "Please sign in first!",
    home: "HOME",
    center: "PERSONAL CENTER",
    user: "WeChat User",
    unAuth: "Sign In",
    info: "Personal Information",
    journey: "Itinerary",
    meal: "Catering",
    stay: "Accommodations",
    searching: "Querying...",
    mealInfo: "Catering Facts",
    mealTime: "Dining Time",
    hotelName: "Hotel",
    hotelAddress: "Hotel Address",
    mealPlace: "Dining Place",
    mealEmpty: "No Data",
    stayInfo: "Accommodations Facts",
    roomType: "Room Type",
    stayEmpty: "No Data",
    journeyInfo: "Schedule",
    arrivalInfo: "Arrivals",
    arrivalType: "Means of Transport",
    arrivalNumber: "Flight/Train Number",
    carNumber: "Car Plate Number",
    arrivalTime: "Time of Arrival",
    pickAddress: "Place of Pick Up",
    departuresInfo: "Departures",
    departuresType: "Means of Transport",
    departuresTime: "Time of Departure",
    vehicleInfo: "Transport",
    dispatchTime: "Dispatch Time",
    driverName: "Driver",
    contactNumber: "Contact Number",
    receptionist: "Guide",
    name: "Name",
    mobile: "Mobile Phone Number",
    sex: "Sex",
    unset: "Not Set",
    save: "Save",
    edit: "Edit",
    submit: "Submit",
    submitTip: "Are you sure to submit?",
    submitSuccess: "Data Submitted Successfully",
    idCardError: "ID Card Number Error",
  },
};

export default testMap;
