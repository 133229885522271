import { defineComponent, Transition } from "vue";
import ActionSheetProps from "./props";
import "./style/action-sheet.less";

export default defineComponent({
  name: "ActionSheet",
  components: { Transition },
  props: { ...ActionSheetProps },
  data() {
    return {
      visible: false,
      show: false,
    };
  },
  methods: {
    showActionSheet() {
      this.show = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.visible = true;
        }, 0);
      });
    },
    hideActionSheet() {
      this.visible = false;
      setTimeout(() => {
        this.show = false;
      }, 300);
    },
    handleClick(index: number) {
      this.hideActionSheet();
      const fn = this.fn;
      if (typeof fn === "function") {
        fn(index);
      }
    },
  },
  render() {
    const itemList = this.itemList || [];
    const itemColor = this.itemColor;
    const listClass = this.visible
      ? "c-cover-content action-sheet-list cover-content-show"
      : "c-cover-content action-sheet-list";
    return (
      <div class="c-cover action-sheet-box" v-show={this.show}>
        <transition name="fade">
          <div
            class="c-cover-back"
            onClick={this.hideActionSheet}
            v-show={this.visible}
          ></div>
        </transition>
        <div
          class={listClass}
          style={itemColor ? { color: itemColor } : undefined}
        >
          {itemList.map((item, index) => (
            <div
              class="action-sheet-item"
              onClick={() => this.handleClick(index)}
            >
              {item}
            </div>
          ))}
          <div
            class="action-sheet-item action-sheet-cancel"
            onClick={this.hideActionSheet}
          >
            取消
          </div>
        </div>
      </div>
    );
  },
});
