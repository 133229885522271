import { PropType } from "vue";
import { CUploadProps } from "@/ui/upload/type";

export default {
  modelValue: {
    type: Array as PropType<CUploadProps["modelValue"]>,
  },

  /* 图片文件大小限制 */
  limit: {
    type: Number,
    default: 10 * 1024 * 1024,
  },
  /* 文件数量限制 */
  count: {
    type: [Number, String],
    default: 1,
  },
  /* 文件类型 1图片 2视频 */
  type: {
    type: Number,
    default: 1,
  },
  /* 视频文件大小限制 */
  maxVideoSize: {
    type: Number,
    default: 50 * 1024 * 1024,
  },
  /* 视频时长限制 */
  maxDuration: [Number, String],
  /* 示例图片 */
  demo: String,
  /* 选择后是否自动上传 */
  auto: {
    type: Boolean,
    default: true,
  },
};
