import { defineComponent, StyleValue } from "vue";
import { PickerColumnData } from "./type";
import { PickerColumnProps } from "./props";
import { Range } from "./type";
import "./style/picker-column.less";

// 惯性常亮
const A = 0.003;
export default defineComponent({
  name: "CPickerColumn",
  props: { ...PickerColumnProps },
  data(): PickerColumnData {
    const w = document.body.clientWidth;
    const scale = w / 375;
    const itemHeight = 48 * scale;
    const initY = 2 * itemHeight;
    return {
      width: 0,
      height: 240 * scale,
      itemHeight,
      initY,
      y: 0, // 纵向偏移 初始为第一个
      animation: false,
      timestamp: 0,
      sy: 0, // 滑动速度计算距离
      cy: 0, // 滑动过程中上次滑动的Y坐标
      oy: 0, // 滑动过程中本次Y坐标与上次Y坐标之间的偏移量 用于判断滑动方向
      position: 0, // 滑动起始纵向偏移量
      ori: 0, // 滑动起始Y坐标
    };
  },
  computed: {
    listClass(): string {
      const base = "picker-cell-list";
      return `${base}${this.animation ? " animation" : ""}`;
    },
  },
  watch: {
    index() {
      this.setY();
    },
  },
  created(): void {
    this.setY();
  },
  mounted(): void {
    this.width = this.$el?.clientWidth || 0;
  },
  methods: {
    setY(): void {
      const index = this.index;
      const itemHeight = this.itemHeight;
      this.y = this.initY - index * itemHeight;
    },
    handleTouchStart(e: TouchEvent): void {
      this.timestamp = Date.now();
      this.cy = this.ori = e.targetTouches[0].pageY;
      this.animation = false;
      this.position = this.y;
      this.oy = 0;
      this.sy = 0;
    },
    handleTouchMove(e: TouchEvent): void {
      e.preventDefault();
      const y = e.targetTouches[0].pageY;
      const oy = y - this.cy;
      // 方向发生改变 重置起始时间及坐标 用于同一个方向的惯性移动计算
      if (oy * this.oy < 0) {
        this.timestamp = Date.now();
        this.sy = 0;
      } else {
        this.sy += oy;
      }
      this.cy = y;
      const offset = y - this.ori;
      this.y = this.position + offset;
    },
    handleTouchEnd(e: TouchEvent): void {
      e.preventDefault();
      let ih = this.itemHeight;
      if (ih === 0) {
        ih = this.itemHeight = this.$el.querySelector(
          "picker-column-select"
        ).clientHeight;
      }
      let offset = this.y - this.position;
      const sy = this.sy;
      const timeOffset = Date.now() - this.timestamp;
      // 手指滑动速度 px/ms
      const speed = sy / timeOffset;
      // 根据牛顿第一定律计算 惯性移动距离
      const inertia = Math.pow(speed, 2) / A;
      // 根据滑动方向增加惯性移动距离
      if (sy > 0) {
        offset += inertia;
      } else if (sy < 0) {
        offset -= inertia;
      }
      // 四舍五入计算滑动到那个元素
      const num = Math.round(offset / ih);
      let y = this.position + num * ih;
      const top = 2 * ih;
      const bottom = (3 - this.range.length) * ih;
      //超出边界范围回弹到边界
      if (y > top) {
        y = top;
      } else if (y < bottom) {
        y = bottom;
      }
      this.animation = true;
      this.y = y;
      setTimeout(() => {
        const num = Math.round((y - this.position) / ih);
        if (num != 0) {
          const index = this.index - num;
          this.$emit("update", index);
        }
      }, 300);
    },
    getCellStyle(cell: Range): StyleValue {
      const width = this.width;
      const style: StyleValue = {};
      if (width > 0) {
        const rangeKey = this.rangeKey;
        const val =
          (typeof cell === "object" && rangeKey ? cell[rangeKey] : cell) + "";
        if (val.length > 5) {
          let size = Math.floor(width / val.length);
          if (size > 16) {
            size = 16;
          }
          style.fontSize = size + "px";
        }
      }
      return style;
    },
  },
  render() {
    return (
      <div
        class="picker-column"
        onTouchstart={this.handleTouchStart}
        onTouchmove={this.handleTouchMove}
        onTouchend={this.handleTouchEnd}
      >
        <div
          class={this.listClass}
          style={`transform: translateY(${this.y}px)`}
        >
          {this.range.map((item) => (
            <div class="picker-cell-item" style={this.getCellStyle(item)}>
              {typeof item === "object" && this.rangeKey
                ? item[this.rangeKey]
                : item}
            </div>
          ))}
        </div>
        <div class="picker-column-mask"></div>
        <div class="picker-column-select"></div>
      </div>
    );
  },
});
