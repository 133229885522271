//页面初始化执行代码

/** * 对Date的扩展，将 Date 转化为指定格式的String * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q)
 可以用 1-2 个占位符 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) * eg: * (new
 Date()).pattern("yyyy-MM-dd hh:mm:ss.S")==> 2006-07-02 08:09:04.423
 * (new Date()).pattern("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 * (new Date()).pattern("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 * (new Date()).pattern("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 * (new Date()).pattern("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 */
Date.prototype.pattern = function (fmt) {
  const o: Record<string, number> = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  const week: Record<string, string> = {
    "0": "\u65e5",
    "1": "\u4e00",
    "2": "\u4e8c",
    "3": "\u4e09",
    "4": "\u56db",
    "5": "\u4e94",
    "6": "\u516d",
  };
  const yr: Array<string> | null = /(y+)/.exec(fmt);
  if (yr !== null) {
    const str = yr[0];
    fmt = fmt.replace(str, (this.getFullYear() + "").substring(4 - str.length));
  }
  const er: Array<string> | null = /(E+)/.exec(fmt);
  if (er !== null) {
    const str = er[0];
    fmt = fmt.replace(
      str,
      (str.length > 1 ? (str.length > 2 ? "\u661f\u671f" : "\u5468") : "") +
        week[this.getDay() + ""]
    );
  }
  for (const k in o) {
    const val = o[k];
    const kr: Array<string> | null = new RegExp("(" + k + ")").exec(fmt);
    if (kr !== null) {
      const str = kr[0];
      fmt = fmt.replace(
        str,
        str.length == 1 ? val + "" : ("00" + val).substring(("" + val).length)
      );
    }
  }
  return fmt;
};

// import VConsole from "vconsole";
// new VConsole();
