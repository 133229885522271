
import { defineComponent, computed, watch, provide } from "vue";
import { RoutePattern } from "@/store/type";
import { useStore } from "vuex";

export default defineComponent({
  name: "AppView",
  setup() {
    const store = useStore();
    const include = computed<RoutePattern>(() => store.getters.include);
    const exclude = computed<RoutePattern>(() => store.getters.exclude);
    const lang = computed(() => store.getters.lang);
    const dom = document.querySelector("#app");
    const setAppClass = () => {
      if (dom) {
        dom.className = lang.value;
      }
    };
    provide("lang", lang);
    watch(lang, setAppClass);
    setAppClass();
    return {
      include,
      exclude,
    };
  },
});
