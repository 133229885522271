import { defineComponent, VNode, h, Transition } from "vue";
import {
  renderVNode,
  renderVNodeByString,
  RenderOptions,
} from "../utils/render";
import CIcon from "../icon";
import ModalProps from "./props";
import { IconConfig, IconTypeNameMap } from "./type";
import "./style/modal.less";
const iconTypeNameMap: IconTypeNameMap = {
  success: {
    className: "modal-icon-success",
    iconName: "check-circle-filled",
  },
  info: {
    className: "modal-icon-info",
    iconName: "info-circle-filled",
  },
  warning: {
    className: "modal-icon-warning",
    iconName: "error-circle-filled",
  },
  error: {
    className: "modal-icon-error",
    iconName: "error-circle-filled",
  },
};
const cancelBtnDefaultText = "取消";
const confirmBtnDefaultText = "确定";
const cancelBtnRenderOptions: RenderOptions = {
  tag: "div",
  props: {
    class: "c-modal-btn c-modal-cancel",
  },
};
const confirmBtnRenderOptions: RenderOptions = {
  tag: "div",
  props: {
    class: "c-modal-btn c-modal-confirm",
  },
};
const modalBtnGap: VNode = h("div", { class: "c-modal-btn-gap" });

export default defineComponent({
  name: "CModal",
  props: { ...ModalProps },
  components: {
    CIcon,
    Transition,
  },
  data() {
    return {
      vs: false,
    };
  },
  computed: {
    boxClass(): string {
      const position = this.position;
      return `c-modal-box c-modal-${position}`;
    },
  },
  methods: {
    show() {
      this.vs = true;
    },
    hide() {
      this.vs = false;
    },
    renderIcon(): VNode[] {
      const icon = this.icon;
      if (icon) {
        const iconConfig: IconConfig = iconTypeNameMap[icon];
        return [
          <div
            class={
              "c-modal-icon" +
              (iconConfig.className ? " " + iconConfig.className : "")
            }
          >
            <c-icon name={iconConfig.iconName}></c-icon>
          </div>,
        ];
      } else {
        return [];
      }
    },
    renderButtons(): VNode {
      return (
        <div class="c-modal-button">
          {this.renderCancelBtn()}
          {this.renderConfirmBtn()}
        </div>
      );
    },
    renderCancelBtn(): VNode[] {
      const cancelBtn = this.cancelBtn;
      if (cancelBtn === false) {
        return [];
      } else {
        const onCancel = this.onCancel;
        const { tag, props } = cancelBtnRenderOptions;
        const renderOptions: RenderOptions = {
          tag,
          props: {
            ...props,
            onClick: () => {
              this.hide();
              typeof onCancel === "function" && onCancel();
            },
          },
        };
        if (cancelBtn) {
          return renderVNode(
            this.$slots.cancelBtn,
            this.cancelBtn as string | VNode,
            renderOptions
          ).concat([modalBtnGap]);
        } else {
          return renderVNodeByString(
            cancelBtnDefaultText,
            renderOptions
          ).concat([modalBtnGap]);
        }
      }
    },
    renderConfirmBtn(): VNode[] {
      const confirmBtn = this.confirmBtn;
      const onConfirm = this.onConfirm;
      const { tag, props } = confirmBtnRenderOptions;
      const renderOptions: RenderOptions = {
        tag,
        props: {
          ...props,
          onClick: () => {
            !this.hold && this.hide();
            typeof onConfirm === "function" && onConfirm();
          },
        },
      };
      if (confirmBtn) {
        return renderVNode(
          this.$slots.confirmBtn,
          this.confirmBtn as string | VNode,
          renderOptions
        );
      } else {
        return renderVNodeByString(confirmBtnDefaultText, renderOptions);
      }
    },
  },
  render() {
    return this.vs ? (
      <transition name="fade">
        <div class={`c-modal c-modal-${this.modalType}`}>
          <div class={this.boxClass}>
            <div class="c-modal-top">
              {this.renderIcon()}
              {renderVNode(this.$slots.title, this.title, {
                tag: "div",
                props: {
                  class: "c-modal-title",
                },
              })}
              <div class="c-modal-content">
                {renderVNode(this.$slots.default, this.content, {
                  tag: "div",
                  props: {},
                })}
              </div>
            </div>
            {this.renderButtons()}
          </div>
        </div>
      </transition>
    ) : null;
  },
});
