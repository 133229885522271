import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "c-toast" }
const _hoisted_2 = {
  key: 0,
  class: "c-toast-mask"
}
const _hoisted_3 = {
  key: 1,
  class: "c-toast-box"
}
const _hoisted_4 = { class: "c-toast-text" }
const _hoisted_5 = {
  key: 2,
  class: "c-toast-line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_icon = _resolveComponent("c-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mask)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.iconName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_c_icon, {
            name: _ctx.iconName,
            class: "c-toast-icon"
          }, null, 8, ["name"]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.text), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.text), 1))
  ]))
}