import http from "@/api/request";
const wx = window.wx;

interface WeChatConfig {
  beta: boolean;
  debug: boolean;
  appId: string;
  timestamp: string;
  nonceStr: string;
  signature: string;
  jsApiList: string[];
  openTagList?: string[];
}

export interface WeixinJSBridge {
  invoke: (
    type: string,
    options: object,
    fn?: () => void,
    useCapture?: boolean
  ) => void;
}

export interface Wx {
  config: (config: WeChatConfig) => void;
  ready: (fn: () => void) => void;
  updateAppMessageShareData: (options: {
    title?: string;
    desc?: string;
    link?: string;
    imgUrl?: string;
  }) => void;
  updateTimelineShareData: (options: {
    title?: string;
    link?: string;
    imgUrl?: string;
  }) => void;
  previewImage: (options: { current: string; urls: string[] }) => void;
  scanQRCode: (options: { needResult: number; scanType: string[] }) => void;
  closeWindow: () => void;
  openLocation: (options: {
    latitude: number;
    longitude: number;
    name?: string;
    address?: string;
    scale?: number;
    infoUrl?: string;
  }) => void;
}

const api = [
  "updateAppMessageShareData",
  "updateTimelineShareData",
  "previewImage",
];
const wxReq = function (
  fn?: () => void,
  apiList?: string[],
  openTagList: string[] = []
) {
  http
    .post<WeChatConfig>("/zsfz/jssdk", {
      url: location.href,
      jsApiList: apiList || api,
    })
    .then((res) => {
      const data = res.data;
      let ticket: WeChatConfig;
      if (typeof data === "string") {
        ticket = JSON.parse(data);
      } else {
        ticket = data;
      }
      wx.config({
        beta: true,
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: ticket.appId, // 必填，公众号的唯一标识
        timestamp: ticket.timestamp, // 必填，生成签名的时间戳
        nonceStr: ticket.nonceStr, // 必填，生成签名的随机串
        signature: ticket.signature, // 必填，签名，见附录1
        jsApiList: apiList || api, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        openTagList,
      });
      typeof fn === "function" && fn();
    });
};
const wxConf = function (title: string, desc: string, logo: string) {
  wxReq(() => {
    share(title, desc, logo);
  });
};

const share = function (
  title?: string,
  desc?: string,
  logo?: string,
  link?: string
) {
  return new Promise<void>((resolve) => {
    const imgUrl =
      logo ||
      "https://zk-static-1301841918.cos.ap-nanjing.myqcloud.com/wb/mobile/share.png";
    title = title || document.title;
    desc = desc || "";
    link = link || location.href;
    wx.ready(function () {
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
      });
      wx.updateTimelineShareData({
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
      });
      resolve();
    });
  });
};
const previewImage = (current: string, urls: string[]) => {
  wx.previewImage({
    current, // 当前显示图片的http链接
    urls, // 需要预览的图片http链接列表
  });
};

export { wxConf, previewImage, wxReq, share };
