import { defineComponent } from "vue";
import { RadioProps } from "@/ui/radio/props";
import { RadioItem } from "@/ui/radio/type";
import mixin from "@/ui/form/mixin";
import "./style/radio.less";

export default defineComponent({
  name: "CRadio",
  props: { ...RadioProps },
  mixins: [mixin],
  computed: {
    valList() {
      const multiple = this.multiple;
      const modelValue = this.modelValue;
      return multiple && modelValue ? String(modelValue).split(",") : [];
    },
  },
  methods: {
    selectItem(item: RadioItem): void {
      let value = item.value;
      if (this.multiple) {
        value = String(value);
        const valList = [...this.valList];
        const i = valList.indexOf(value);
        if (i === -1) {
          valList.push(value);
        } else {
          valList.splice(i, 1);
        }
        value = valList.join(",");
      }
      this.$emit("update:modelValue", value);
      this.notifyCheck();
    },
    getRadioGroupClass(): string {
      return `radio-group radio-group-${this.layout}`;
    },
    getRadioItemClass(item: RadioItem): string {
      let base = `radio-item radio-type-${this.type}`;
      const multiple = this.multiple;
      const modelValue = this.modelValue;
      const valList = this.valList;
      if (
        (multiple && valList.indexOf(String(item.value)) >= 0) ||
        item.value === modelValue
      ) {
        base += " radio-select";
      }
      return base;
    },
  },
  render() {
    return (
      <div class={this.getRadioGroupClass()}>
        {this.options.map((item) => (
          <div
            class={this.getRadioItemClass(item)}
            onClick={() => this.selectItem(item)}
            style={this.radioStyle}
          >
            {this.type == "icon" ? (
              <div class="radio-icon">
                <c-icon name="check-circle-filled" class="radio-check"></c-icon>
              </div>
            ) : null}
            <div class="radio-txt">{item.text}</div>
          </div>
        ))}
      </div>
    );
  },
});
