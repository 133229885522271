import { LabelInstance } from "@/ui/label/type";

export default {
  computed: {
    CLabel(this: vm.VmType): LabelInstance {
      let parent = this.$parent;
      while (parent && parent.$options.name !== "CLabel") {
        parent = parent.$parent;
      }
      return parent as LabelInstance;
    },
  },
  methods: {
    /**
     * 默认不根据表单状态直接检查
     * @param error true表示检查未校验通过的表单
     */
    notifyCheck(this: vm.VmType, error = false) {
      const CLabel = this.CLabel;
      if (CLabel && (!error || CLabel.state === "error")) {
        CLabel.checkProp();
      }
    },
  },
};
