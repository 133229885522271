import { computed } from "vue";
import store from "@/store";
import text from "./text";
import { Lang } from "@/store/modules/reception";

export const language = computed<Lang>(() => store.getters.lang);

const t = computed(() => {
  return text[language.value] || {};
});

export default t;
