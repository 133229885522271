import { isEmpty } from "@/common/tool";
import { LabelRule, LabelRuleValue } from "./type";

export const checkValue = (rules: Array<LabelRule>, value: unknown): string => {
  let tip = "";
  if (rules) {
    if (isEmpty(value)) {
      const require = rules.find((r) => r.required === true);
      if (require) {
        tip = require.message;
      }
    } else {
      rules.every((rule: LabelRule) => {
        let msg = rule.message;
        let key: keyof LabelRule;
        for (key in rule) {
          if (key === "message") return true;
          const rv: LabelRuleValue = rule[key];
          if (key == "min" || key == "max") {
            let l: number;
            if (rule.type === "number") {
              l = Number(value);
              if (isNaN(l)) {
                msg = "请输入数字";
                return false;
              }
            } else {
              l = (value + "").length;
            }
            if (rv && ((key == "min" && l < rv) || (key == "max" && l > rv))) {
              tip = msg;
              return false;
            }
          } else if (key == "regex") {
            const regexp = new RegExp(rv as string);
            if (!regexp.test(value + "")) {
              tip = msg;
              return false;
            }
          } else {
            return true;
          }
        }
      });
    }
  }
  return tip;
};
