import { defineComponent } from "vue";
import IconProps from "./props";
import "./style/icon.less";

const req = require.context("./svg", false, /\.svg$/);
req.keys().map(req);

export default defineComponent({
  name: "CIcon",
  props: { ...IconProps },
  computed: {
    iconName() {
      return `#icon-${this.name}`;
    },
  },
  render() {
    return this.name === "loading" ? (
      <div class="c-loading-icon">
        <span class="c-loading-dot"></span>
      </div>
    ) : (
      <svg width="1em" height="1em" class="svg-icon">
        <use xlinkHref={this.iconName} />
      </svg>
    );
  },
});
