import { CToastIconType } from "./type";
import { PropType } from "vue";

export default {
  /**
   * 图标 支持 success error loading none
   * */
  icon: {
    type: String as PropType<CToastIconType>,
    default: "none",
  },
  title: {
    type: String,
    default: "",
    required: true,
  },
  mask: {
    type: Boolean,
    default: false,
  },
  duration: {
    type: Number,
    default: 1500,
  },
};
