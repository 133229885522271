import { App, createApp, Plugin } from "vue";
import { ModalOptions, ModalMethod, HideMethod } from "./type";
import Modal from "./modal";
type ModalPlugin = Plugin & ModalMethod;
type ModalInstance = InstanceType<typeof Modal>;
let modalInstance: ModalInstance | null = null;

export const hideModal: HideMethod = () => {
  if (modalInstance) {
    modalInstance.hide();
    modalInstance = null;
  }
};

const createModal: ModalMethod = (props) => {
  const options: ModalOptions = { ...props };
  const box = document.createElement("div");
  const instance: ModalInstance = createApp(Modal, options).mount(
    box
  ) as ModalInstance;
  document.body.appendChild(instance.$el);
  modalInstance = instance;
  modalInstance.show();
};

const ModalPlugin: ModalPlugin = createModal as ModalPlugin;

ModalPlugin.install = (app: App) => {
  app.config.globalProperties.$showModal = ModalPlugin;
  app.config.globalProperties.$hideModal = hideModal;
};

export default ModalPlugin;
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $showModal: ModalMethod;
    $hideModal: HideMethod;
  }
}
