import { PropType } from "vue";
import { CInputProps } from "./type";

export default {
  type: {
    type: String as PropType<CInputProps["type"]>,
    default: "text",
  },
  modelValue: {
    type: [String, Number],
  },
};
