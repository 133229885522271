import { defineComponent } from "vue";
import "./style/tip.less";

export default defineComponent({
  name: "CTip",
  props: {
    note: String,
  },
  render() {
    return (
      <div class="c-tip">
        {this.note
          ? this.note.split(/[\n\r]/).map((p) => <p class="c-tip-text">{p}</p>)
          : null}
      </div>
    );
  },
});
