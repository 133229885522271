/* eslint-disable  @typescript-eslint/no-explicit-any */
import { h, VNode } from "vue";
import { Slot, VNodeProps } from "@vue/runtime-core";

type RawProps = VNodeProps & {
  __v_isVNode?: never;
  [Symbol.iterator]?: never;
} & Record<string, any>;

export type RenderOptions = {
  tag: string;
  props: RawProps;
};

export const renderVNode = (
  slot: Slot | undefined,
  value: string | VNode | undefined,
  options: RenderOptions
): VNode[] => {
  if (slot) {
    return slot();
  } else {
    if (value) {
      if (typeof value === "object") {
        return [value];
      } else {
        return renderVNodeByString(value as string, options);
      }
    } else {
      return [];
    }
  }
};

export const renderVNodeByString = (
  value: string,
  options: RenderOptions
): VNode[] => {
  return [h(options.tag, options.props, [value])];
};
