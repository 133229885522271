import { defineComponent } from "vue";
import FormProps from "./props";
import { CFormData } from "./type";
import { LabelInstance } from "../label/type";
export default defineComponent({
  name: "CForm",
  props: { ...FormProps },
  data(): CFormData {
    return {
      labels: [],
    };
  },
  methods: {
    addLabel(label: LabelInstance): void {
      this.labels.push(label);
    },
    removeLabel(label: LabelInstance): void {
      const index = this.labels.findIndex((item) => item === label);
      this.labels.splice(index, 1);
    },
    validate(): string {
      const labels = this.labels;
      let error = "";
      for (let i = 0, l = labels.length; i < l; i++) {
        const item = labels[i];
        const tip = item.checkProp();
        if (!error) {
          error = tip;
        }
      }
      return error;
    },
    prevent(e: Event) {
      e.preventDefault();
      return false;
    },
  },
  render() {
    return (
      <form class="c-form" onSubmit={this.prevent}>
        {this.$slots.default?.()}
      </form>
    );
  },
});
