export default {
  /**
   * 选中日期
   * */
  selected: {
    type: String,
    default: null,
  },
  /**
   * 是否显示日历月份开关
   * */
  month: {
    type: Boolean,
    default: true,
  },
  /**
   * 是否显示日历月份手动调整开关
   * */
  change: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否显示农历日期
   * */
  lunar: Boolean,
  disabled: Boolean,
  startDate: String,
  endDate: String,
};
