import { defineComponent } from "vue";
import mixin from "../form/mixin";
import { CheckboxProps } from "./props";
import CIcon from "../icon/icon";
import "./style/checkbox.less";

export default defineComponent({
  name: "CCheckbox",
  components: { CIcon },
  mixins: [mixin],
  props: { ...CheckboxProps },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    modelValue(val: boolean) {
      this.checked = val;
    },
  },
  created(): void {
    this.checked = this.modelValue;
  },
  methods: {
    handleClick() {
      this.checked = !this.checked;
      this.$emit("update:modelValue", this.checked);
      this.notifyCheck();
    },
  },
  render() {
    return (
      <div class="checkbox" onClick={this.handleClick}>
        {this.checked ? (
          <c-icon name="check-circle-filled" class="checkbox-checked"></c-icon>
        ) : (
          <div class="checkbox-unchecked"></div>
        )}
        {this.$slots.default ? (
          <div class="checkbox-label">{this.$slots.default?.()}</div>
        ) : null}
      </div>
    );
  },
});
