import { defineComponent } from "vue";
import ButtonProps from "./props";
import "./style/button.less";

export default defineComponent({
  name: "CButton",
  props: { ...ButtonProps },
  computed: {
    className(): string {
      const base = "c-button";
      const type = this.type;
      const size = this.size;
      let res = `${base} c-button-${type} c-button-${size}`;
      if (this.disabled) {
        res += " c-button-disabled";
      }
      return res;
    },
  },
  render() {
    return (
      <button type="button" class={this.className}>
        {this.$slots.default?.()}
      </button>
    );
  },
});
